import { render, staticRenderFns } from "./templateMetadata.vue?vue&type=template&id=931e1f78&scoped=true&"
import script from "./templateMetadata.vue?vue&type=script&lang=js&"
export * from "./templateMetadata.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "931e1f78",
  null
  
)

export default component.exports